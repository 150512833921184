<template>
  <v-app>
    <div class="card card-custom">
      <div class="card-body p-0">
        <div
            class="wizard wizard-2"
            id="kt_wizard_v2"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
        >
          <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
            <div class="row">
              <div class="col-6">
                <h3>
                  Attendance report
                </h3>
                <router-link :to="{name:'dashboard'}">
                  Dashboard
                </router-link>
                \
                Attendance report

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--        <div class="card card-custom mt-2" v-if="!loading">-->
    <!--            <div class="card-body">-->
    <!--                <form @submit.prevent="" class="row bg-filter">-->
    <!--                    <div class="col-lg-3">-->
    <!--                        <v-select-->
    <!--                                v-model="attendanceFilter.year"-->
    <!--                                :items="years"-->
    <!--                                outlined-->
    <!--                                dense-->
    <!--                                label="Year"  >-->
    <!--                        </v-select>-->
    <!--                    </div>-->
    <!--                    <div class="col-lg-3">-->
    <!--                        <v-select-->
    <!--                                v-model="attendanceFilter.month"-->
    <!--                                :items="monthNames"-->
    <!--                                item-value="value"-->
    <!--                                item-text="name"-->
    <!--                                outlined-->
    <!--                                dense-->
    <!--                                label="Month">-->
    <!--                        </v-select>-->
    <!--                    </div>-->
    <!--                    <div class="col-lg-3">-->
    <!--                        <v-select-->
    <!--                                :loading="loading"-->
    <!--                                v-model="attendanceFilter.course_id"-->
    <!--                                :items="grade_courses"-->
    <!--                                item-value="id"-->
    <!--                                item-text="title"-->
    <!--                                outlined-->
    <!--                                dense-->
    <!--                                label="Subjects">-->
    <!--                        </v-select>-->
    <!--                    </div>-->
    <!--                    <div class="col-lg-2">-->
    <!--                        <button-->
    <!--                                @click="getReports"-->
    <!--                                class=" btn btn-primary font-size-lg"-->
    <!--                        >Filter-->
    <!--                        </button>-->
    <!--                    </div>-->
    <!--                </form>-->

    <!--            </div>-->
    <!--        </div>-->
    <div v-if="!loading">
      <div class="row mt-2">

        <div class="col-md-5">
          <div class="card">
            <div class="card-body">

              <div v-if="attendacneReportSummary">
                <div class="row attendance-course-list pt-2 pb-2" v-for="(course,index) in attendacneReportSummary" :key="index">
                  <div class="col-md-12 align-items-center">
                    <h5>{{course.subject}} <span class="float-right badge badge-dark" title="Total class taught">{{course.total_class_ran}}</span></h5>
                  </div>
                  <div class="col-md-3 text-center font-size-lg">
                    <h2>{{course.total_days}}</h2>

                  </div>
                  <div class="col-md-3  text-center font-size-lg red--text">
                    <h2>{{course.total_absent}}</h2>
                    Absent
                  </div>
                  <div class="col-md-3  text-center font-size-lg green--text">
                    <h2>{{course.total_present}}</h2>
                    Present
                  </div>
                  <!--                              <div class="col-md-3  text-center font-size-lg">-->
                  <!--                                <h2>{{course.total_half_day}}</h2>-->
                  <!--                                Half Day-->
                  <!--                              </div>-->
                  <div class="col-md-3  text-center font-size-lg orange--text">
                    <h2>{{course.total_late}}</h2>
                    Late
                  </div>
                </div>
              </div>
              <div v-else>
                No attendance records available. Please try again later.
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-7">
          <div class="card">
            <div class="card-body">
              <v-sheet height="64">
                <v-toolbar
                    flat
                >

                  <v-btn
                      outlined
                      class="mr-4"
                      color="grey darken-2"
                      @click="setToday"
                  >
                    Today
                  </v-btn>
                  <v-btn
                      fab
                      text
                      small
                      color="grey darken-2"
                      @click="prev"
                  >
                    <v-icon small>
                      mdi-chevron-left
                    </v-icon>
                  </v-btn>
                  <v-btn
                      fab
                      text
                      small
                      color="grey darken-2"
                      @click="next"
                  >
                    <v-icon small>
                      mdi-chevron-right
                    </v-icon>
                  </v-btn>
                  <v-toolbar-title v-if="$refs.calendar">
                    {{ $refs.calendar.title }}
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-select
                      :loading="loading"
                      v-model="attendanceFilter.course_id"
                      :items="grade_courses"
                      item-value="id"
                      item-text="title"
                      outlined
                      dense
                      @change="getReports"
                      label="Subjects">
                  </v-select>
                </v-toolbar>
              </v-sheet>
              <v-sheet height="400">
                <v-calendar
                    ref="calendar"
                    v-model="focus"
                    :type="ctype"
                    :events="calender_attendacne_items"
                    @click:event="showEvent"
                    :event-more="more"
                    category-show-all
                    :event-overlap-threshold="30"
                ></v-calendar>
                <v-menu
                    v-model="selectedOpen"
                    :close-on-content-click="false"
                    :activator="selectedElement"
                    offset-x
                >
                  <v-card
                      color="grey lighten-4"
                      min-width="350px"
                      flat
                  >
                    <v-toolbar
                        :color="selectedEvent.color"
                        dark
                    >

                      <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
                      <v-spacer></v-spacer>

                    </v-toolbar>
                    <v-card-text>
                      <span v-html="selectedEvent.content"></span>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn
                          text
                          color="secondary"
                          @click="selectedOpen = false"
                      >
                        Cancel
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-menu>
              </v-sheet>
              <!--                            <AttendanceCalendar-->
              <!--                                    :calender_attendacne_items="calender_attendacne_items"></AttendanceCalendar>-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="loading">

      <div class="row mt-3">
        <div class="col-md-8">
          <v-skeleton-loader
              type="list-item-three-line"
          ></v-skeleton-loader>
          <v-skeleton-loader
              type="list-item-three-line"
          ></v-skeleton-loader>
          <v-skeleton-loader
              type="list-item-three-line"
          ></v-skeleton-loader>
        </div>
        <div class="col-md-4">
          <v-skeleton-loader
              type="card"
          ></v-skeleton-loader>
        </div>
      </div>

    </div>
  </v-app>
</template>
<script>
;
import AttendanceCalendar from "@/view/pages/attendance/Calendar";
import {CalenderMixin} from "@/mixins/CalenderMixin";
import StudentSettingService from "@/core/services/user/student/StudentSettingService";

const studentSettingService = new StudentSettingService();
export default {
  name: "studentAttendance",
  components: {AttendanceCalendar},
  mixins: [CalenderMixin],

  data() {
    var d = new Date(),
        month = d.getMonth(),
        year = d.getYear();
    return {

      grades:{},
      days: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      monthNames: [
        {
          name: "January",
          value: "01",
        },
        {
          name: "February",
          value: '02',
        },
        {
          name: "March",
          value: "03",
        },
        {
          name: "April",
          value: "04",
        },
        {
          name: "May",
          value: "05",
        },
        {
          name: "June",
          value: "06",
        }, {
          name: "July",
          value: "07",
        }, {
          name: "August",
          value: "08",
        }, {
          name: "September",
          value: "09",
        },
        {
          name: "October",
          value: 10,
        }, {
          name: "November",
          value: 11,
        }, {
          name: "December",
          value: 12,
        },
      ],
      selectedEvent: {},
      selectedElement: null,
      more: false,
      selectedOpen: false,
      mode: 'column',
      focus: '',
      currentMonthIndex: null,
      ctype: 'month',
      types: ['month', 'week', 'day', '4day'],
      typeToLabel: {
        month: 'Month',
        week: 'Week',
        day: 'Day',
        '4day': '4 Days',
      },
      events: [],
      grade_courses: [],
    };
  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    },
    years() {
      var year = new Date().getFullYear();
      var i = year - 5;
      var years = [];
      for (i; i <= year; i++) {
        years.push(i);
      }
      return years;
    },
  },
  mounted() {
    this.getAttendanceReport();
    this.getAttendanceSummary();
    this.getGrades();
    this.currentMonthIndex = this.$refs.calendar.times.now.month;
    if (this.grade_courses && this.grade_courses.length){
      this.attendanceFilter.course_id = this.grade_courses[0];
    }

  },
  methods: {
    getGrades() {
      let grade = this.grades;
      this.loading = true;
      studentSettingService.getGrades(this.search).then(response => {
        this.grade_courses = response.data.data;
        this.loading = false;
      }).catch(error=>{
        console.log(error)
      }).finally(()=>{
        this.loading = false;
      });
    },
    prev() {
      this.currentMonthIndex--;
      this.attendanceFilter.month = this.currentMonthIndex
      this.attendanceFilter.month =  ((this.currentMonthIndex)).toString().padStart(2, '0');
      this.getReports();
      this.$refs.calendar.prev()
    },
    setToday() {
      this.focus = ''
    },
    next() {
      this.currentMonthIndex++;
      this.attendanceFilter.month = this.currentMonthIndex
      this.attendanceFilter.month =  ((this.currentMonthIndex)).toString().padStart(2, '0');
      this.getReports();
      console.log(this.$refs.calendar.times.now.month)
      this.$refs.calendar.next()
    },
    showEvent({nativeEvent, event}) {
      const open = () => {
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target
        setTimeout(() => {
          this.selectedOpen = true
        }, 10)
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        setTimeout(open, 10)
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },
  }
};
</script>

<style scoped>

.attenedance table {
  border-collapse: collapse;
}

.attenedance table, th, td {
  border: 1px solid black;
}
</style>
